import React, { useEffect } from 'react';
import Layout from '../components/Common/Layout';
import MainWidth from '../components/Common/MainWidth';
import Seo from '../components/Common/Seo';
import { initializeGa4,sendPageView } from '../utils/ga4';
import { initializeHotjar } from '../utils/hotjar';

export default (props: any) => {

  useEffect(() => {
    initializeHotjar();
    initializeGa4();
    sendPageView({
      page: `/privacy-policy`,
      title: "Privacy Policy"
    });
  }, []);

  return (
    <Layout hash={props?.location?.hash}>
      <Seo
        title="software with purpose"
        description="craftable software specialises in systems that require quality, reliability and scalability in a highly transactional environment for complex software ecosystems."
      />
      <span id="privacy-policy"></span>
      <div className="policy_container">
        <MainWidth>
          <h1>Privacy Policy</h1>
          <p>
            Your privacy is important to us. It is craftable software's policy to respect your privacy
            regarding any information we may collect from you across our website, craftable software,
            and other sites we own and operate.
          </p>
          <p>
            We only ask for personal information when we truly need it to provide a service to you. We
            collect it by fair and lawful means, with your knowledge and consent. We also let you know
            why we’re collecting it and how it will be used.
          </p>
          <p>
            We only retain collected information for as long as necessary to provide you with your
            requested service. What data we store, we’ll protect within commercially acceptable means
            to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or
            modification.
          </p>
          <p>
            We don’t share any personally identifying information publicly or with third-parties,
            except when required to by law.
          </p>
          <p>
            Our website may link to external sites that are not operated by us. Please be aware that
            we have no control over the content and practices of these sites, and cannot accept
            responsibility or liability for their respective privacy policies.
          </p>
          <p>
            You are free to refuse our request for your personal information, with the understanding
            that we may be unable to provide you with some of your desired services.
          </p>
          <p>
            Your continued use of our website will be regarded as acceptance of our practices around
            privacy and personal information. If you have any questions about how we handle user data
            and personal information, feel free to contact us.
          </p>

          <div id="cookies">
            <h2>Cookie Policy for craftable software</h2>
            <p>
              This is the Cookie Policy for craftable software, accessible from URL{' '}
              <a href="www.craftablesoftware.com"> www.craftablesoftware.com </a>
            </p>
            <h3 id="what-are-cookies">What Are Cookies</h3>
            <p>
              As is common practice with almost all professional websites this site uses cookies,
              which are tiny files that are downloaded to your computer, to improve your experience.
              This page describes what information they gather, how we use it and why we sometimes
              need to store these cookies. We will also share how you can prevent these cookies from
              being stored however this may downgrade or 'break' certain elements of the sites’
              functionality.{' '}
            </p>
            <h3 id="how-we-use-cookies">How we use cookies</h3>
            <p>
              We use cookies for a variety of reasons detailed below. Unfortunately in most cases
              there are no industry standard options for disabling cookies without completely
              disabling the functionality and features they add to this site. It is recommended that
              you leave on all cookies if you are not sure whether you need them or not in case they
              are used to provide a service that you use.
            </p>
            <h3 id="disabling-cookies">Disabling Cookies</h3>
            <p>
              You can prevent the setting of cookies by adjusting the settings on your browser (see
              your browser Help for how to do this). Be aware that disabling cookies will affect the
              functionality of this and many other websites that you visit. Disabling cookies will
              usually result in also disabling certain functionality and features of this site.
              Therefore it is recommended that you do not disable cookies.
            </p>
            <h3 id="the-cookies-we-set">The Cookies We Set</h3>
            <ul>
              <li>
                Email newsletters related cookies. This site offers newsletter or email subscription
                services and cookies may be used to remember if you are already registered and whether
                to show certain notifications which might only be valid to subscribed/unsubscribed
                users.
              </li>
              <li>
                Surveys related cookies. From time to time we offer user surveys and questionnaires to
                provide you with interesting insights, helpful tools, or to understand our user base
                more accurately. These surveys may use cookies to remember who has already taken part
                in a survey or to provide you with accurate results after you change pages.
              </li>
              <li>
                Forms related cookies. When you submit data through a form such as those found on
                contact pages or comment forms cookies may be set to remember your user details for
                future correspondence.
              </li>
              <li>
                Site preferences cookies. In order to provide you with a great experience on this site
                we provide the functionality to set your preferences for how this site runs when you
                use it. In order to remember your preferences we need to set cookies so that this
                information can be called whenever you interact with a page that is affected by your
                preferences.
              </li>
            </ul>
            <h3 id="third-party-cookies">Third Party Cookies</h3>
            <p>
              In some special cases we also use cookies provided by trusted third parties. The
              following section details which third party cookies you might encounter through this
              site.
            </p>
            <ul>
              <li>
                This site uses Google Analytics which is one of the most widespread and trusted
                analytics solution on the web for helping us to understand how you use the site and
                ways that we can improve your experience. These cookies may track things such as how
                long you spend on the site and the pages that you visit so we can continue to produce
                engaging content. For more information on Google Analytics cookies, see the official
                Google Analytics page.
              </li>
              <li>
                Third party analytics are used to track and measure usage of this site so that we can
                continue to produce engaging content. These cookies may track things such as how long
                you spend on the site or pages you visit which helps us to understand how we can
                improve the site for you.
              </li>
              <li>
                From time to time we test new features and make subtle changes to the way that the
                site is delivered. When we are still testing new features these cookies may be used to
                ensure that you receive a consistent experience whilst on the site whilst ensuring we
                understand which optimisations our users appreciate the most.
              </li>
              <li>
                As we sell products it's important for us to understand statistics about how many of
                the visitors to our site actually make a purchase and as such this is the kind of data
                that these cookies will track. This is important to you as it means that we can
                accurately make business predictions that allow us to monitor our advertising and
                product costs to ensure the best possible price.
              </li>
              <li>
                We also use social media buttons and/or plugins on this site that allow you to connect
                with your social network in various ways. For these to work the following social media
                sites including: Linkedin, Twitter, Medium, will set cookies through our site which
                may be used to enhance your profile on their site or contribute to the data they hold
                for various purposes outlined in their respective privacy policies.
              </li>
              <li>
                Hotjar may temporarily process your IP address so that it can ensure their service is
                running smoothly and improve the quality of the software. Any IP addresses it
                processes are used exclusively for associated performance metrics and to monitor and
                track application errors. For this purpose, Hotjar may temporarily store your IP
                address with Hotjar’s Sub-Processors which are subject to strict obligations of
                confidentiality and will process it only according to instructions. Hotjar will never
                access these IP addresses without any operational or security need. Hotjar
                automatically deletes any IP addresses it processes or stores within thirty (30)
                calendar days. The legal basis for this data processing is Article 6(1)(f) GDPR.
              </li>
              <li>
                Some Hotjar Customers may have the ability to integrate data they have collected
                through Hotjar Software with other end-user data they have in their possession (e.g.
                their customer information).
              </li>
              <li>
                Depending on the web browser you use, it might be possible for you to disallow Hotjar
                from collecting data when visiting a Hotjar Enabled Site. To discover if your web
                browser offers this functionality visit the Do Not Track page.
              </li>
            </ul>
          </div>
          <div id="more-information">
            <h2>More Information</h2>
            <p>
              Hopefully that has clarified things for you and as was previously mentioned if there is
              something that you aren't sure whether you need or not it's usually safer to leave
              cookies enabled in case it does interact with one of the features you use on our site.
              This policy is effective as of November 2021.
            </p>
          </div>
          <div id="terms-of-service"></div>
          <div>
            <h2> Terms of Service</h2>
            <h3 id="terms"> Terms</h3>
            <p>
              By accessing the website at craftable software you are agreeing to be bound by these
              terms of service, all applicable laws and regulations, and agree that you are
              responsible for compliance with any applicable local laws. If you do not agree with any
              of these terms, you are prohibited from using or accessing this site. The materials
              contained in this website are protected by applicable copyright and trademark law.
            </p>
            <h3 id="use-license">Use License</h3>
            <p>
              Permission is granted to temporarily download one copy of the materials (information or
              software) on craftable software's website for personal, non-commercial transitory
              viewing only. This is the grant of a licence, not a transfer of title, and under this
              licence you may not:
            </p>
            <ul>
              <li>Modify or copy the materials; </li>
              <li>
                Use the materials for any commercial purpose, or for any public display (commercial or
                non-commercial);{' '}
              </li>
              <li>
                Attempt to decompile or reverse engineer any software contained on craftable software
                website;
              </li>
              <li>Remove any copyright or other proprietary notations from the materials; </li>
              <li>
                Transfer the materials to another person or 'mirror' the materials on any other
                server.
              </li>
            </ul>
          </div>
          <h3 id="disclaimer">Disclaimer</h3>
          <p>
            The materials on craftable software's website are provided on an 'as is' basis. craftable
            software makes no warranties, expressed or implied, and hereby disclaims and negates all
            other warranties including, without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or non-infringement of intellectual
            property or other violation of rights.
          </p>
          <p>
            Further, craftable software does not warrant or make any representations concerning the
            accuracy, likely results, or reliability of the use of the materials on its website or
            otherwise relating to such materials or on any sites linked to this site.
          </p>
          <h3 id="limitations">Limitations</h3>
          <p>
            In no event shall craftable software or its suppliers be liable for any damages
            (including, without limitation, damages for loss of data or profit, or due to business
            interruption) arising out of the use or inability to use the materials on craftable
            software's website, even if craftable software or a craftable software authorised
            representative has been notified orally or in writing of the possibility of such damage.
            Because some jurisdictions do not allow limitations on implied warranties, or limitations
            of liability for consequential or incidental damages, these limitations may not apply to
            you.
          </p>
          <h3 id="accuracy-of-materials">Accuracy of materials</h3>
          <p>
            The materials appearing on craftable software's website could include technical,
            typographical, or photographic errors. craftable software does not warrant that any of the
            materials on its website are accurate, complete or current. craftable software may make
            changes to the materials contained on its website at any time without notice. However,
            craftable software does not make any commitment to update the materials.
          </p>
          <h3 id="links">Links</h3>
          <p>
            Craftable software has not reviewed all of the sites linked to its website and is not
            responsible for the contents of any such linked site. The inclusion of any link does not
            imply endorsement by craftable software of the site. Use of any such linked website is at
            the user's own risk.
          </p>
          <h3 id="modifications">Modifications</h3>
          <p>
            Craftable software may revise these terms of service for its website at any time without
            notice. By using this website you are agreeing to be bound by the then current version of
            these terms of service.
          </p>
          <h3 id="governing-law">Governing Law</h3>
          <p>Terms and conditions defined by craftable software.</p>
        </MainWidth>
      </div>
    </Layout>
  )
}
